import React from "react"

import { Link } from "gatsby"

// Components
import Cart from "./cart"

const Menu = ({ open }) => {
  return (
    <div
      className={`${
        open ? "flex md:flex" : "hidden md:flex"
      } flex-col justify-between flex-basis-100 md:items-center md:flex-basis-auto md:flex-row`}
    >
      <Link
        to="/how-to"
        className="py-3 md:px-4 md:py-6 hover:opacity-70 font-medium"
      >
        Navodila
      </Link>
      <Link
        to="/contact"
        className="py-3 md:px-4 md:py-6 hover:opacity-70 font-medium"
      >
        Kontakt
      </Link>
      <div className="hidden md:block">
        <Cart />
      </div>
    </div>
  )
}

export default Menu
