import React from "react"

// Assets
import MenuIcon from "../../images/icons/menu.inline.svg"
import CloseIcon from "../../images/icons/close-menu.inline.svg"

const MenuButton = ({ toggleOpen, open }) => {
  return (
    <button
      aria-label="Menu button"
      className="flex items-center justify-center border rounded-md border-gray-400  bg-transparent p-2 md:hidden"
      onClick={() => toggleOpen(!open)}
    >
      {open ? <CloseIcon /> : <MenuIcon />}
    </button>
  )
}

export default MenuButton
