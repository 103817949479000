import React from "react"
import { Link } from "gatsby"

const Menu = () => {
  return (
    <div className="max-w-screen-xl mx-auto py-8 px-4 md:py-16 grid grid-cols-1 gap-6 md:grid-cols-3">
      <div>
        <span className="block uppercase font-semibold text-lg mb-3 border-b pb-4">
          Meni
        </span>
        <div className="grid grid-cols-1">
          <Link
            to="/how-to"
            className="py-3 hover:opacity-70 font-medium transition duration-100 ease-in-out transform hover:translate-x-1"
          >
            Navodila
          </Link>
          <Link
            to="/contact"
            className="py-3 hover:opacity-70 font-medium transition duration-100 ease-in-out transform hover:translate-x-1"
          >
            Kontakt
          </Link>
        </div>
      </div>
      <div>
        <span className="block uppercase font-semibold text-lg mb-3 border-b pb-4">
          Pomoč
        </span>
        <div className="grid grid-cols-1">
          <Link
            to="/faqs"
            className="py-3 hover:opacity-70 font-medium transition duration-100 ease-in-out transform hover:translate-x-1"
          >
            Pogosta vprašanja
          </Link>
          <Link
            to="/terms-of-use"
            className="py-3 hover:opacity-70 font-medium transition duration-100 ease-in-out transform hover:translate-x-1"
          >
            Splošni Pogoji
          </Link>
          <Link
            to="/cookies"
            className="py-3 hover:opacity-70 font-medium transition duration-100 ease-in-out transform hover:translate-x-1"
          >
            Piškotki
          </Link>
        </div>
      </div>
      <div>
        <span className="block uppercase font-semibold text-lg mb-3 border-b pb-4">
          Deli z mano
        </span>
        <p>
          Prenehaj z občudovanjem vseh popolnih fotografij, ki jih zaslediš na
          spletu. Začni jih ustvarjati! Uporabi hashtag spodaj in se pridruži
          največjemu editing community-u v Sloveniji!
        </p>
        <a
          href="https://www.instagram.com/explore/tags/presetsbylilicadez"
          target="_blank"
          rel="noreferrer"
          className="font-semibold mt-4 block hover:opacity-70 transition duration-100 ease-in-out"
        >
          #Presetsbylilicadez{" "}
          <span role="img" aria-label="Heart">
            ❤️
          </span>
        </a>
      </div>
    </div>
  )
}

export default Menu
