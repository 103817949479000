import React from "react"

// Components
import PaymentOptions from "../paymentOptions/index"
import Socials from "./socials"

const Copyrights = () => {
  return (
    <div className="border-t px-4">
      <div className="max-w-screen-xl mx-auto py-6 flex flex-col gap-4 md:flex-row items-center justify-between">
        <p className="text-xs order-3 md:order-1">©Lili Cadez 2022</p>
        <Socials />
        <PaymentOptions styles="order-2 md:order-3" />
      </div>
    </div>
  )
}

export default Copyrights
