import React, { useContext } from "react"
import { Link } from "gatsby"

// Assets
import CartIcon from "../../images/icons/cart.inline.svg"
// Context
import { StoreContext } from "../../context/store-context"

const Cart = () => {
  const { checkout } = useContext(StoreContext)

  const items = checkout ? checkout.lineItems : []

  const quantity = items.reduce((total, item) => {
    return total + item.quantity
  }, 0)

  return (
    <Link
      aria-label={`Shopping Cart with ${quantity} items`}
      to="/cart"
      className="items-center py-5 px-4 justify-center rounded-md bg-transparent p-2 flex"
    >
      <CartIcon />
      <sup className="font-semibold text-base">{quantity}</sup>
    </Link>
  )
}

export default Cart
