import React from "react"

// Assets
import ArrowUpIcon from "../../images/icons/arrow-up.inline.svg"

const BackToTop = () => {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" })

  return (
    <button
      className="uppercase font-medium flex items-center transition duration-100 hover:opacity-70"
      onClick={() => scrollTop()}
    >
      Nazaj na vrh
      <div className="p-1 rounded-full bg-gray-300 ml-2 flex items-center justify-center transform duration-200 hover:bg-gray-200">
        <ArrowUpIcon />
      </div>
    </button>
  )
}

export default BackToTop
