import React, { useState } from "react"

// Components
import Logo from "./logo"
import Menu from "./menu"
import MenuButton from "./menuButton"
import Cart from "./cart"

const Header = ({ menu }) => {
  const [open, toggleOpen] = useState(false)

  return (
    <header
      className={`px-4 bg-white border-b sticky top-0 z-50 ${
        open ? "pb-3 md:pb-0" : "pb-0 md:pb-0"
      }`}
    >
      <div className="max-w-screen-xl mx-auto flex items-center flex-wrap justify-between">
        <Logo />
        <div className="flex items-center md:hidden">
          {/* <Cart /> */}
          {!menu && <MenuButton toggleOpen={toggleOpen} open={open} />}
        </div>
        <Menu open={open} />
      </div>
    </header>
  )
}

export default Header
