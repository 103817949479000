import React from "react"
import Link from "gatsby-link"

const TopBar = () => {
  return (
    <div className="bg-primary-100 relative overflow-hidden">
      <a
        rel="noreferrer"
        target="_blank"
        href="https://influspace.agency/creators/products/the-creator-mobile-video-luts-filters"
        className="py-3 px-4 text-center text-sm font-semibold block uppercase"
        style={{ zIndex: 5 }}
      >
        <strong>NOVO:</strong> The Creator Video LUTS Paket 🎥 -{" "}
        <u>
          Tukaj{" "}
          <span role="img" aria-label="Arrow Icon">
            ➡️
          </span>
        </u>
      </a>
    </div>
  )
}

export default TopBar
