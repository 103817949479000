import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getCurrentLangKey } from "ptz-i18n"
import { IntlProvider } from "react-intl"
import loadable from "@loadable/component"

// Context
import { StoreContext } from "../context/store-context"

// Components
import TopBar from "./promotions/topBar"
import Header from "./header/index"
import Footer from "./footer"
const Cookies = loadable(() => import("./cookies"))
const AddedToCart = loadable(() => import("./notification/addedToCart.js"))

const Layout = ({ children, path }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          languages {
            langs
            defaultLangKey
          }
        }
      }
    }
  `)

  const { didJustAddToCart } = useContext(StoreContext)

  // langs = ["en", "sl"] defaultLangKey = "sl"
  const { langs, defaultLangKey } = data.site.siteMetadata.languages

  // returns currently selected key
  let langKey = getCurrentLangKey(langs, defaultLangKey, path)

  // returns link with selected language

  // Get's the correct file based on selected language
  let i18nMessages = require(`../data/messages/sl`)

  return (
    <IntlProvider locale={langKey} messages={i18nMessages}>
      <TopBar />
      <Header />
      <main>{children}</main>
      <Footer />
      {path === "/links" || path === "/links/" ? null : <Cookies />}
      {didJustAddToCart && <AddedToCart />}
    </IntlProvider>
  )
}

export default Layout
