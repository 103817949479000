import React from "react"
import { Link } from "gatsby"

// Assets
import LogoIcon from "../../images/icons/logo.inline.svg"

const Logo = () => {
  return (
    <Link to="/" className="py-3" aria-label="Lili Cadez">
      <LogoIcon />
      <p
        className="text-xs text-white max-w-max px-3 font-medium py-0.5 rounded-lg"
        style={{ background: "#028955" }}
      >
        InfluSpace
      </p>
    </Link>
  )
}

export default Logo
