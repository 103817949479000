import React from "react"

const Socials = () => {
  return (
    <div className="flex items-center gap-4 order-1 md:order-2">
      <a
        className="text-sm font-medium hover:opacity-70"
        href="https://instagram.com/lilicadez"
        target="_blank"
        rel="noreferrer"
      >
        Instagram
      </a>
      <a
        className="text-sm font-medium hover:opacity-70"
        href="https://facebook.com/shoplilicadezpresets"
        target="_blank"
        rel="noreferrer"
      >
        Facebook
      </a>
      <a
        className="text-sm font-medium hover:opacity-70"
        href="https://tiktok.com/@cadezlili"
        target="_blank"
        rel="noreferrer"
      >
        TikTok
      </a>
    </div>
  )
}

export default Socials
