module.exports = {
  // Promotion Message in the banner
  promoSubMessage: "Cinematica Mobile - Film Photography",
  promoMessage: "Dokumentiraj svoje življenje kot v filmu.",
  promoCTA: "Kupi Zdaj",
  // Benefits
  benefit1: "8129+ Happy Customers",
  benefit1FirstWord: "Čudovite",
  benefit1SecondWord: "Fotografije",
  benefit2: "Varno Plačilo",
  benefit2FirstWord: "Varno",
  benefit2SecondWord: "Plačilo",
  benefit3: "100% Prilagodljivo",
  benefit4: "Takojšen Prenos",
  benefit4FirstWord: "Takojšen",
  benefit4SecondWord: "Prenos",
  benefit5: "8129+ Happy Customers",
  benefit5FirstWord: "8129+",
  benefit5SecondWord: "Happy Customers",
  benefit6: "Lili priporoča! 😘",
  // Tags
  new: "Novo",
  save: "Save",
  recommended: "Lili priporoča 😘",
  // Headings
  heading1: "Nori popusti",
  heading2: "Najbolj prodajani preseti",
  heading3: "Preseti, ki res delujejo",
  heading4: "UREJAJ SLIKE Z ENIM KLIKOM ― NIKOLI LAŽJE",
  heading5: "KAJ SO LIGHTROOM PRESETI?",
  heading6: "Vsi preseti",
  heading7: "Vse E-Knjige",
  heading8: "Morda ti bo prav tako všeč",
  heading9: "Sledi mi in prejmi skrivnostne ponudbe. 🤫",
  heading10: "Kontakt",
  heading11: "Brez Panike",
  // Subheadings
  subheading4:
    "Ustvari profesionalen izgled na svojem Instagram feed-u. Začni urejati svoje fotke z enim samim klikom in v sekundi doseži željen rezultat.",
  subheading5:
    "Lightroom Preseti so kot filtri, ki spremenijo tvojo sliko z enim samim klikom. Najlažji način, da si zagotoviš usklajene slike in estetičen izgled.",
  subheading6: "Promo kode, najnovejše novičke, novosti in še več!",
  subheading7: "Dreamy fotografije v sekundi. Z enim klikom. 😍",
  // Buttons
  findYourPresets: "Najdi svoje Presete",
  addToCart: "Dodaj v Košarico",
  send: "Pošlji",
  shopNow: "Kupi zdaj",
  addComment: "Dodaj Komentar",
  subscribe: "Prijava",
  backToTop: "Nazaj na vrh",
  // Navigation Links
  help: "Pomoč",
  presets: "Preseti",
  freebies: "Brezplačno",
  beforeAndAfter: "Prej & Potem",
  ebooks: "E-Knjige",
  howTo: "Navodila",
  contact: "Kontakt",
  faqs: "Pogosta vprašanja",
  terms: "Pogoji poslovanja",
  privacyPolicy: "Varstvo osebnih podatkov",
  paymentPolicy: "Plačilni pogoji",
  community: "Pridruži se",
  cookies: "Piškotki",
  // Titles
  navigation: "Meni",
  shareWithMe: "Deli z mano",
  paymentOptions: "Možnosti plačila",
  // Content
  paymentOptionsContent:
    "Plačilo preko TRR - Pošlji mi email na presets@lilicadez.com ali me kontaktiraj na Instagramu @lilicadez",
  shareWithMeContent:
    "Prenehaj z občudovanjem vseh popolnih fotografij, ki jih zaslediš na spletu. Začni jih ustvarjati! Komaj čakam, da vidim kako si uporabil moje presete. Uporabi hashtag spodaj in se pridruži družinici!",
  noReviewsContent: "Ta izdelek še nima komentarja. Bodi prvi!",
  moreExamplesContent: "Poglej si še več primerov Before & After na",
  noResultsContent: "Nažalost nisem našla ničesar, prosim poskusi ponovno! ❤️",
  // Search
  searchPresets: "Poišči Presete",
  bundles: "Bundli 📦",
  bestSellers: "Najbolj Prodajano 💯",
  discounted: "Znižano 🤑",
  freeTag: "Brezplačno 🎁",
  newTag: "Novo ❤️",
  clearAll: "Počisti vse",
  results: "Rezultati",
  // Upsells
  upsell1: "Prihrani z nakupom Bundla! 🎉",
  upsell2: "Kupi 1 dobiš 2! Brezplačen preset lahko izbereš v košarici. 😘",
  // Newsletter
  noSpam: "Nič vsiljivega. Odjavi se kadarkoli.",
  newsletterConsent:
    "Želim se prijaviti na e-novice in se strinjam z vsemi Splošnimi pogoji in Politiko zasebnosti.",
  // Lightroom
  downloadLightroom: "Prenesi brezplačno Adobe® Lightroom® Aplikacijo",
  onePlace: "Vse na enem mestu",
}
