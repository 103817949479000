import React from "react"

// Assets
import AppleBadge from "../../images/badges/apple-store-badge.inline.svg"
import GoogleBadge from "../../images/badges/google-play-badge.inline.svg"

const DownloadBadges = () => {
  return (
    <div className="mb-4 sm:mb-0">
      <p className="text-xs text-gray-700 mb-2 font-medium">
        Prenesi brezplačno Adobe Lightroom® Aplikacijo
      </p>
      <div className="flex items-center flex-wrap justify-center sm:justify-start">
        <a
          aria-label="App Store"
          target="_blank"
          rel="noreferrer"
          href="https://apps.apple.com/us/app/adobe-lightroom-photo-editor/id878783582"
          className="mr-2"
        >
          <AppleBadge />
        </a>
        <a
          aria-label="Google Play store"
          target="_blank"
          rel="noreferrer"
          href="https://play.google.com/store/apps/details?id=com.adobe.lrmobile&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        >
          <GoogleBadge />
        </a>
      </div>
    </div>
  )
}

export default DownloadBadges
