import React from "react"

// Assets
import VisaIcon from "../../images/icons/visa.inline.svg"
import MastercardIcon from "../../images/icons/mastercard.inline.svg"
import PayPalIcon from "../../images/icons/paypal.inline.svg"
import MaestroIcon from "../../images/icons/maestro.inline.svg"

const PaymentOptions = ({ styles }) => {
  return (
    <div className={`flex items-center gap-2 ${styles}`}>
      <MastercardIcon />
      <VisaIcon />
      <PayPalIcon />
      <MaestroIcon />
    </div>
  )
}

export default PaymentOptions
