import React from "react"

// Components
import Menu from "./menu"
import Copyrights from "./copyrights"
import DownloadBadges from "./downloadBadges"
import BackToTop from "./backToTop"

const Footer = () => {
  return (
    <footer className="border-t">
      <Menu />
      <div className="max-w-screen-xl mx-auto py-6 sm:py-8 px-4 flex items-center justify-between flex-col text-center sm:text-left sm:flex-row">
        <DownloadBadges />
        <BackToTop />
      </div>
      <Copyrights />
    </footer>
  )
}

export default Footer
